p.typewriter::after {
  content: "|";
  animation: blinkCursor 1000ms infinite;
}

@keyframes blinkCursor {
  0%, 100% {
    opacity: 30%;
  }

  20%, 80% {
    opacity: 100%;
  }
}