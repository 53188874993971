.fading-rects_rect__0n9ul {
  animation: fading-rects_fadeIn__qgewB 5s ease infinite;
}

.fading-rects_rectAlt__q9dk9 {
  animation: fading-rects_fadeIn__qgewB 5s ease infinite;
  animation-delay: 2.5s;
}

@keyframes fading-rects_fadeIn__qgewB {
  0%  { opacity: 0 }
  50%  { opacity: 1 }
  100%  { opacity: 0 }
}

p.type-writer_typewriter__BXlsA::after {
  content: "|";
  animation: type-writer_blinkCursor__yl9kY 1000ms infinite;
}

@keyframes type-writer_blinkCursor__yl9kY {
  0%, 100% {
    opacity: 30%;
  }

  20%, 80% {
    opacity: 100%;
  }
}
:root {
  --adm-radius-s: 4px;
  --adm-radius-m: 8px;
  --adm-radius-l: 12px;
  --adm-font-size-1: 9px;
  --adm-font-size-2: 10px;
  --adm-font-size-3: 11px;
  --adm-font-size-4: 12px;
  --adm-font-size-5: 13px;
  --adm-font-size-6: 14px;
  --adm-font-size-7: 15px;
  --adm-font-size-8: 16px;
  --adm-font-size-9: 17px;
  --adm-font-size-10: 18px;
  --adm-color-primary: #1677ff;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-white: #ffffff;
  --adm-color-text: #333333;
  --adm-color-text-secondary: #666666;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-color-border: #eeeeee;
  --adm-color-box: #f5f5f5;
  --adm-color-background: #ffffff;
  --adm-font-size-main: var(--adm-font-size-5);
  --adm-font-family: -apple-system, blinkmacsystemfont, 'Helvetica Neue',
    helvetica, segoe ui, arial, roboto, 'PingFang SC', 'miui',
    'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
  --adm-border-color: var(--adm-color-border);
}
html[data-prefers-color-scheme='dark'] {
  --adm-color-primary: #3086ff;
  --adm-color-success: #34b368;
  --adm-color-warning: #ffa930;
  --adm-color-danger: #ff4a58;
  --adm-color-text: #e6e6e6;
  --adm-color-text-secondary: #b3b3b3;
  --adm-color-weak: #808080;
  --adm-color-light: #4d4d4d;
  --adm-color-border: #2b2b2b;
  --adm-color-box: #0a0a0a;
  --adm-color-background: #1a1a1a;
  --adm-border-color: var(--adm-color-border);
}
:root {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  color: var(--adm-color-text);
  font-size: var(--adm-font-size-main);
  font-family: var(--adm-font-family);
}
a,
button {
  cursor: pointer;
}
a {
  color: var(--adm-color-primary);
  transition: opacity ease-in-out 0.2s;
}
a:active {
  opacity: 0.8;
}
.adm-plain-anchor {
  color: unset;
  transition: none;
}
.adm-plain-anchor:active {
  opacity: unset;
}
body.adm-overflow-hidden {
  overflow: hidden !important;
}
div.adm-px-tester {
  --size: 1;
  height: calc(var(--size) / 2 * 2px);
  width: 0;
  position: fixed;
  right: -100vw;
  bottom: -100vh;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}

.adm-swiper {
  --height: auto;
  --width: 100%;
  --border-radius: 0;
  --track-padding: 0;
  --slide-size: 100%;
  --track-offset: 0%;
  display: block;
  width: var(--width);
  height: var(--height);
  position: relative;
  border-radius: var(--border-radius);
  z-index: 0;
  overflow: hidden;
}
.adm-swiper-track {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  padding: var(--track-padding);
}
.adm-swiper-track-allow-touch-move {
  cursor: grab;
}
.adm-swiper-track-inner {
  width: 100%;
  height: 100%;
  overflow: visible;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
}
.adm-swiper-slide {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  white-space: unset;
  flex: none;
}
.adm-swiper-item {
  display: block;
  width: 100%;
  height: 100%;
  white-space: normal;
}
.adm-swiper-horizontal .adm-swiper-track-allow-touch-move {
  touch-action: pan-y;
}
.adm-swiper-horizontal .adm-swiper-indicator {
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
}
.adm-swiper-horizontal .adm-swiper-track {
  transform: translateX(var(--track-offset));
}
.adm-swiper-horizontal .adm-swiper-track-inner {
  flex-direction: row;
  width: var(--slide-size);
}
.adm-swiper-vertical .adm-swiper-track-allow-touch-move {
  touch-action: pan-x;
}
.adm-swiper-vertical .adm-swiper-indicator {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
}
.adm-swiper-vertical .adm-swiper-track {
  transform: translateY(var(--track-offset));
}
.adm-swiper-vertical .adm-swiper-track-inner {
  flex-direction: column;
  height: var(--slide-size);
}

.adm-page-indicator {
  display: flex;
  width: auto;
  --dot-color: rgba(0, 0, 0, 0.2);
  --active-dot-color: var(--adm-color-primary);
  --dot-size: 3px;
  --active-dot-size: 13px;
  --dot-border-radius: 1px;
  --active-dot-border-radius: var(--dot-border-radius);
  --dot-spacing: 3px;
}
.adm-page-indicator-dot {
  display: block;
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: var(--dot-border-radius);
  background: var(--dot-color);
}
.adm-page-indicator-dot:last-child {
  margin-right: 0;
}
.adm-page-indicator-dot-active {
  border-radius: var(--active-dot-border-radius);
  background: var(--active-dot-color);
}
.adm-page-indicator-color-white {
  --active-dot-color: var(--adm-color-white);
}
.adm-page-indicator-horizontal {
  flex-direction: row;
}
.adm-page-indicator-horizontal .adm-page-indicator-dot {
  margin-right: var(--dot-spacing);
}
.adm-page-indicator-horizontal .adm-page-indicator-dot-active {
  width: var(--active-dot-size);
}
.adm-page-indicator-vertical {
  flex-direction: column;
}
.adm-page-indicator-vertical .adm-page-indicator-dot {
  margin-bottom: var(--dot-spacing);
}
.adm-page-indicator-vertical .adm-page-indicator-dot-active {
  height: var(--active-dot-size);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-space {
  display: inline-flex;
}
.ant-space-vertical {
  flex-direction: column;
}
.ant-space-align-center {
  align-items: center;
}
.ant-space-align-start {
  align-items: flex-start;
}
.ant-space-align-end {
  align-items: flex-end;
}
.ant-space-align-baseline {
  align-items: baseline;
}
.ant-space-item:empty {
  display: none;
}
.ant-space-compact {
  display: inline-flex;
}
.ant-space-compact-block {
  display: flex;
  width: 100%;
}
.ant-space-compact-vertical {
  flex-direction: column;
}
.ant-space-rtl {
  direction: rtl;
}
.ant-space-compact-rtl {
  direction: rtl;
}
.ant-statistic-title {
  color: #060606;
}
.ant-breadcrumb li a,
.ant-breadcrumb .ant-breadcrumb-separator {
  color: #D2D3DD;
}
.ant-breadcrumb li:last-child a {
  color: #FFFCFB;
  border-bottom: #FFFCFB 2px solid;
}
.ant-collapse {
  border: 1px solid transparent;
}
.ant-collapse-content > .ant-collapse-content-box,
.ant-collapse-content.ant-collapse-content-active {
  background-color: #1E2024;
  border-radius: 0 0 8px 8px;
  border-top: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-item {
  background-color: #FFFFFF1A;
  border-radius: 16px;
  padding: 12px 16px 12px 16px;
  color: #D2D3DD;
  display: flex;
  align-items: center;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item div,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-item div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #5B5C6C;
  border-radius: 16px;
  padding: 12px 16px 12px 16px;
  color: #FFFCFB;
  display: flex;
  align-items: center;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical {
  background-color: transparent;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table-thead th.ant-table-column-sort,
tr.ant-table-placeholder > td,
tr.ant-table-placeholder div {
  transition-duration: 500ms;
  background: #000000;
  color: #FFFCFB;
}
.ant-table-thead th:hover,
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover,
tr.ant-table-placeholder:hover,
tr.ant-table-placeholder:hover div {
  transition-duration: 500ms;
  background: #FFFCFB;
  color: #000000;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #8687A0;
}
.ant-carousel .slick-next::before {
  content: '';
}
.ant-carousel .slick-prev::before {
  content: '';
}
.ant-carousel .slick-slide > div {
  margin: 0 15px;
}
.ant-carousel .slick-list {
  margin: 0 -15px;
}
.ant-switch {
  background-color: #BFBFBF;
}
.ant-switch-checked {
  background-color: #E96036;
}
.ant-btn-primary {
  position: relative !important;
  border-color: transparent;
  background: #FF7826;
  color: #FFFCFB;
}
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  border-color: transparent;
  background: #E96036;
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  border-color: transparent;
  background: #D2D3DD;
  color: #ABACBC;
}
.ant-btn-secondary {
  position: relative;
  border: 1px solid #FF7826;
  background: transparent;
  color: #FF7826;
}
.ant-btn-secondary:focus,
.ant-btn-secondary:hover {
  border: 1px solid #E96036;
  background: transparent;
  color: #E96036;
}
.ant-btn-secondary[disabled],
.ant-btn-secondary[disabled]:focus,
.ant-btn-secondary[disabled]:hover {
  border-color: #ABACBC;
  background: rgba(210, 211, 221, 0.15);
  color: #ABACBC;
}
.ant-btn-text {
  position: relative;
  border-color: transparent;
  background: transparent;
  color: #FF7826;
}
.ant-btn-text[ghost] {
  position: relative;
  border-color: transparent;
  background: transparent;
  color: #FFFCFB;
}
.ant-btn-text:focus,
.ant-btn-text:hover {
  border-color: transparent;
  background: transparent;
  color: #E96036;
}
.ant-btn-text:focus > span,
.ant-btn-text:hover > span {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-decoration-thickness: initial;
  -webkit-text-decoration-style: initial;
          text-decoration-style: initial;
  -webkit-text-decoration-color: initial;
          text-decoration-color: initial;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus > span,
.ant-btn-text[disabled]:hover > span {
  color: #ABACBC;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}
.ant-btn-link {
  position: relative;
  border-color: transparent;
  background: transparent;
  color: #FF7826;
}
.ant-btn-link:focus,
.ant-btn-link:hover {
  border-color: transparent;
  background: transparent;
  color: #E96036;
}
.ant-btn-link > span {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-decoration-thickness: initial;
  -webkit-text-decoration-style: initial;
          text-decoration-style: initial;
  -webkit-text-decoration-color: initial;
          text-decoration-color: initial;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:hover {
  color: #ABACBC;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  gap: 16px;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  margin: 0;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0px;
}
.ant-tabs-tab:not(.ant-tabs-tab-disabled) {
  color: #D2D3DD;
  font-weight: 700;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #FFFCFB;
}
.ant-tabs-ink-bar {
  background: #FFFCFB;
}
.ant-tabs-tab {
  padding: 4px 0;
}
.ant-tabs-tab:not(.ant-tabs-tab-active, .ant-tabs-tab-disabled):hover {
  color: #E96036;
  font-weight: 700;
  border-bottom: 2px solid #E96036;
  padding-bottom: 2px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  color: #8687A0;
  background-color: #1E2024;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 16px 16px 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active + .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active + .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active + .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active + .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #2E2F3A;
  color: #FFFCFB;
  border: 1px 1px 0 1px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled + .ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled + .ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled + .ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled + .ant-tabs-tab.ant-tabs-tab-disabled {
  background-color: #1E202480;
  color: #8687A0;
}
.ant-tabs-tab:not(.ant-tabs-tab-active, .ant-tabs-tab-disabled):hover > .ant-tans-tab-btn > div > span {
  color: #E96036;
  font-weight: 700;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-disabled {
  color: #ABACBC;
}
.ant-slider-mark-text-active {
  color: #000;
}
.ant-slider-dot,
.ant-slider-dot-active {
  width: 0;
  height: 0;
  color: transparent;
  border-color: transparent;
  border-width: 0;
}
.alert .ant-tooltip-inner {
  background-color: #F5222D;
}
.alert .ant-tooltip-arrow-content {
  background-color: #F5222D;
}
.ant-popover {
  z-index: 99;
}
.ant-popover-no-style .ant-popover-arrow-content,
.ant-popover-no-style .ant-popover-arrow-content::before {
  background-color: unset;
  z-index: 99;
  width: 0;
  height: 0;
}
.ant-popover-no-style .ant-popover-inner {
  background-color: unset;
  z-index: 99;
}
.ant-popover-no-style .ant-popover-inner-content {
  padding: 0px;
  z-index: 99;
}
.ant-input-number-input {
  padding-left: 0px;
  padding-right: 0px;
}
.adm-page-indicator-dot {
  transition: all 100ms ease-in;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #E96036 !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  cursor: pointer;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: white !important;
  cursor: pointer !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  color: white !important;
  cursor: default !important;
  pointer-events: none !important;
}
.ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
}
.ant-steps-item-tail {
  color: #8687A0 !important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #8687A0 !important;
  cursor: pointer;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: rgba(55, 182, 112, 0.8) !important;
  background-color: rgba(55, 182, 112, 0.8) !important;
  cursor: pointer;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
  cursor: pointer;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #5B5C6C !important;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #8687A0 !important;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #8687A0 !important;
}
.ant-progress-circle .ant-progress-text {
  font-size: 1.5em !important;
  color: white !important;
}
.ant-select-selection-item {
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
}
.ant-select-arrow {
  color: white !important;
}
.ant-select-selector {
  height: 40px !important;
  width: 20rem !important;
  background-color: #1E2024 !important;
  border-width: 1px !important;
  border-color: #D2D3DD !important;
  color: #D2D3DD !important;
  display: flex !important;
  align-items: center !important;
}
.ant-select-open > .ant-select-selector {
  background-color: #D2D3DD !important;
  border-width: 1px !important;
  border-color: #1E2024 !important;
  color: #ACACBC !important;
}

.partners_marquee__l1otm {
  height: 40px;
  width: 100%;
  overflow: hidden;

  position: relative;
  padding: 8px 0 4px 0;
}

.partners_leftToRight__2mOwI {
  animation: 60s partners_leftToRight__2mOwI infinite linear 
}

.partners_rightToLeft__EGGb9 {
  animation: 60s partners_rightToLeft__EGGb9 infinite linear
}

/* even out the elements */
.partners_marqueeDiv__FX0vw {
  position: absolute;
  width: 100%;
  left: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
}

.partners_leftToRight__2mOwI:hover, .partners_rightToLeft__EGGb9:hover {
  animation-play-state: paused;
}

/* add delay at the end of animation so you dont start while another div is going */
@keyframes partners_leftToRight__2mOwI {
  0% { transform: translate(0, 0) }
  91% { transform: translate(80rem, 0) }
  100% { transform: translate(80rem, 0) }
}

@keyframes partners_rightToLeft__EGGb9 {
  0% { transform: translate(0, 0) }
  91% { transform: translate(-80rem, 0) }
  100% { transform: translate(-80rem, 0) }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #060606;
  font-size: 16px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}
.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  touch-action: pan-y;
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '←';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '→';
}
.ant-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 15%;
  margin-bottom: 0;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
  bottom: auto;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 4px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button {
  position: relative;
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li button::after {
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  content: '';
}
.ant-carousel .slick-dots li.slick-active {
  width: 24px;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto;
}
.ant-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 0;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active {
  width: 3px;
  height: 24px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-carousel-rtl {
  direction: rtl;
}
.ant-carousel-rtl .ant-carousel .slick-track {
  right: 0;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev {
  right: -25px;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev::before {
  content: '→';
}
.ant-carousel-rtl .ant-carousel .slick-next {
  right: auto;
  left: -25px;
}
.ant-carousel-rtl .ant-carousel .slick-next::before {
  content: '←';
}
.ant-carousel-rtl.ant-carousel .slick-dots {
  flex-direction: row-reverse;
}
.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  flex-direction: column;
}
.ant-statistic-title {
  color: #060606;
}
.ant-breadcrumb li a,
.ant-breadcrumb .ant-breadcrumb-separator {
  color: #D2D3DD;
}
.ant-breadcrumb li:last-child a {
  color: #FFFCFB;
  border-bottom: #FFFCFB 2px solid;
}
.ant-collapse {
  border: 1px solid transparent;
}
.ant-collapse-content > .ant-collapse-content-box,
.ant-collapse-content.ant-collapse-content-active {
  background-color: #1E2024;
  border-radius: 0 0 8px 8px;
  border-top: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-item {
  background-color: #FFFFFF1A;
  border-radius: 16px;
  padding: 12px 16px 12px 16px;
  color: #D2D3DD;
  display: flex;
  align-items: center;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item div,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-item div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #5B5C6C;
  border-radius: 16px;
  padding: 12px 16px 12px 16px;
  color: #FFFCFB;
  display: flex;
  align-items: center;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical {
  background-color: transparent;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table-thead th.ant-table-column-sort,
tr.ant-table-placeholder > td,
tr.ant-table-placeholder div {
  transition-duration: 500ms;
  background: #000000;
  color: #FFFCFB;
}
.ant-table-thead th:hover,
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover,
tr.ant-table-placeholder:hover,
tr.ant-table-placeholder:hover div {
  transition-duration: 500ms;
  background: #FFFCFB;
  color: #000000;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #8687A0;
}
.ant-carousel .slick-next::before {
  content: '';
}
.ant-carousel .slick-prev::before {
  content: '';
}
.ant-carousel .slick-slide > div {
  margin: 0 15px;
}
.ant-carousel .slick-list {
  margin: 0 -15px;
}
.ant-switch {
  background-color: #BFBFBF;
}
.ant-switch-checked {
  background-color: #E96036;
}
.ant-btn-primary {
  position: relative !important;
  border-color: transparent;
  background: #FF7826;
  color: #FFFCFB;
}
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  border-color: transparent;
  background: #E96036;
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  border-color: transparent;
  background: #D2D3DD;
  color: #ABACBC;
}
.ant-btn-secondary {
  position: relative;
  border: 1px solid #FF7826;
  background: transparent;
  color: #FF7826;
}
.ant-btn-secondary:focus,
.ant-btn-secondary:hover {
  border: 1px solid #E96036;
  background: transparent;
  color: #E96036;
}
.ant-btn-secondary[disabled],
.ant-btn-secondary[disabled]:focus,
.ant-btn-secondary[disabled]:hover {
  border-color: #ABACBC;
  background: rgba(210, 211, 221, 0.15);
  color: #ABACBC;
}
.ant-btn-text {
  position: relative;
  border-color: transparent;
  background: transparent;
  color: #FF7826;
}
.ant-btn-text[ghost] {
  position: relative;
  border-color: transparent;
  background: transparent;
  color: #FFFCFB;
}
.ant-btn-text:focus,
.ant-btn-text:hover {
  border-color: transparent;
  background: transparent;
  color: #E96036;
}
.ant-btn-text:focus > span,
.ant-btn-text:hover > span {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-decoration-thickness: initial;
  -webkit-text-decoration-style: initial;
          text-decoration-style: initial;
  -webkit-text-decoration-color: initial;
          text-decoration-color: initial;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus > span,
.ant-btn-text[disabled]:hover > span {
  color: #ABACBC;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}
.ant-btn-link {
  position: relative;
  border-color: transparent;
  background: transparent;
  color: #FF7826;
}
.ant-btn-link:focus,
.ant-btn-link:hover {
  border-color: transparent;
  background: transparent;
  color: #E96036;
}
.ant-btn-link > span {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-decoration-thickness: initial;
  -webkit-text-decoration-style: initial;
          text-decoration-style: initial;
  -webkit-text-decoration-color: initial;
          text-decoration-color: initial;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:hover {
  color: #ABACBC;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  gap: 16px;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  margin: 0;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0px;
}
.ant-tabs-tab:not(.ant-tabs-tab-disabled) {
  color: #D2D3DD;
  font-weight: 700;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #FFFCFB;
}
.ant-tabs-ink-bar {
  background: #FFFCFB;
}
.ant-tabs-tab {
  padding: 4px 0;
}
.ant-tabs-tab:not(.ant-tabs-tab-active, .ant-tabs-tab-disabled):hover {
  color: #E96036;
  font-weight: 700;
  border-bottom: 2px solid #E96036;
  padding-bottom: 2px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  color: #8687A0;
  background-color: #1E2024;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 16px 16px 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active + .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active + .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active + .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active + .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #2E2F3A;
  color: #FFFCFB;
  border: 1px 1px 0 1px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled + .ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled + .ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled + .ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled + .ant-tabs-tab.ant-tabs-tab-disabled {
  background-color: #1E202480;
  color: #8687A0;
}
.ant-tabs-tab:not(.ant-tabs-tab-active, .ant-tabs-tab-disabled):hover > .ant-tans-tab-btn > div > span {
  color: #E96036;
  font-weight: 700;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-disabled {
  color: #ABACBC;
}
.ant-slider-mark-text-active {
  color: #000;
}
.ant-slider-dot,
.ant-slider-dot-active {
  width: 0;
  height: 0;
  color: transparent;
  border-color: transparent;
  border-width: 0;
}
.alert .ant-tooltip-inner {
  background-color: #F5222D;
}
.alert .ant-tooltip-arrow-content {
  background-color: #F5222D;
}
.ant-popover {
  z-index: 99;
}
.ant-popover-no-style .ant-popover-arrow-content,
.ant-popover-no-style .ant-popover-arrow-content::before {
  background-color: unset;
  z-index: 99;
  width: 0;
  height: 0;
}
.ant-popover-no-style .ant-popover-inner {
  background-color: unset;
  z-index: 99;
}
.ant-popover-no-style .ant-popover-inner-content {
  padding: 0px;
  z-index: 99;
}
.ant-input-number-input {
  padding-left: 0px;
  padding-right: 0px;
}
.adm-page-indicator-dot {
  transition: all 100ms ease-in;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #E96036 !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  cursor: pointer;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: white !important;
  cursor: pointer !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  color: white !important;
  cursor: default !important;
  pointer-events: none !important;
}
.ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
}
.ant-steps-item-tail {
  color: #8687A0 !important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #8687A0 !important;
  cursor: pointer;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: rgba(55, 182, 112, 0.8) !important;
  background-color: rgba(55, 182, 112, 0.8) !important;
  cursor: pointer;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
  cursor: pointer;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #5B5C6C !important;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #8687A0 !important;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #8687A0 !important;
}
.ant-progress-circle .ant-progress-text {
  font-size: 1.5em !important;
  color: white !important;
}
.ant-select-selection-item {
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
}
.ant-select-arrow {
  color: white !important;
}
.ant-select-selector {
  height: 40px !important;
  width: 20rem !important;
  background-color: #1E2024 !important;
  border-width: 1px !important;
  border-color: #D2D3DD !important;
  color: #D2D3DD !important;
  display: flex !important;
  align-items: center !important;
}
.ant-select-open > .ant-select-selector {
  background-color: #D2D3DD !important;
  border-width: 1px !important;
  border-color: #1E2024 !important;
  color: #ACACBC !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #060606;
  font-size: 16px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: #fafafa;
  border: 1px solid #dddddd;
  border-bottom: 0;
  border-radius: 4px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #dddddd;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 4px 4px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  color: #060606;
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  vertical-align: -1px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
  flex: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-left: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  flex: none;
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only .ant-collapse-expand-icon {
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  left: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-content {
  color: #060606;
  background-color: #fff;
  border-top: 1px solid #dddddd;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-hidden {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 4px 4px;
}
.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #dddddd;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item:last-child {
  border-bottom: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse-ghost {
  background-color: transparent;
  border: 0;
}
.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-collapse-rtl {
  direction: rtl;
}
.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
}
.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: auto;
  left: 16px;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 0;
  margin-left: 12px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(180deg);
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
  padding-left: 0;
}
.ant-statistic-title {
  color: #060606;
}
.ant-breadcrumb li a,
.ant-breadcrumb .ant-breadcrumb-separator {
  color: #D2D3DD;
}
.ant-breadcrumb li:last-child a {
  color: #FFFCFB;
  border-bottom: #FFFCFB 2px solid;
}
.ant-collapse {
  border: 1px solid transparent;
}
.ant-collapse-content > .ant-collapse-content-box,
.ant-collapse-content.ant-collapse-content-active {
  background-color: #1E2024;
  border-radius: 0 0 8px 8px;
  border-top: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-item {
  background-color: #FFFFFF1A;
  border-radius: 16px;
  padding: 12px 16px 12px 16px;
  color: #D2D3DD;
  display: flex;
  align-items: center;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item div,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-item div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #5B5C6C;
  border-radius: 16px;
  padding: 12px 16px 12px 16px;
  color: #FFFCFB;
  display: flex;
  align-items: center;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical {
  background-color: transparent;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table-thead th.ant-table-column-sort,
tr.ant-table-placeholder > td,
tr.ant-table-placeholder div {
  transition-duration: 500ms;
  background: #000000;
  color: #FFFCFB;
}
.ant-table-thead th:hover,
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover,
tr.ant-table-placeholder:hover,
tr.ant-table-placeholder:hover div {
  transition-duration: 500ms;
  background: #FFFCFB;
  color: #000000;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #8687A0;
}
.ant-carousel .slick-next::before {
  content: '';
}
.ant-carousel .slick-prev::before {
  content: '';
}
.ant-carousel .slick-slide > div {
  margin: 0 15px;
}
.ant-carousel .slick-list {
  margin: 0 -15px;
}
.ant-switch {
  background-color: #BFBFBF;
}
.ant-switch-checked {
  background-color: #E96036;
}
.ant-btn-primary {
  position: relative !important;
  border-color: transparent;
  background: #FF7826;
  color: #FFFCFB;
}
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  border-color: transparent;
  background: #E96036;
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  border-color: transparent;
  background: #D2D3DD;
  color: #ABACBC;
}
.ant-btn-secondary {
  position: relative;
  border: 1px solid #FF7826;
  background: transparent;
  color: #FF7826;
}
.ant-btn-secondary:focus,
.ant-btn-secondary:hover {
  border: 1px solid #E96036;
  background: transparent;
  color: #E96036;
}
.ant-btn-secondary[disabled],
.ant-btn-secondary[disabled]:focus,
.ant-btn-secondary[disabled]:hover {
  border-color: #ABACBC;
  background: rgba(210, 211, 221, 0.15);
  color: #ABACBC;
}
.ant-btn-text {
  position: relative;
  border-color: transparent;
  background: transparent;
  color: #FF7826;
}
.ant-btn-text[ghost] {
  position: relative;
  border-color: transparent;
  background: transparent;
  color: #FFFCFB;
}
.ant-btn-text:focus,
.ant-btn-text:hover {
  border-color: transparent;
  background: transparent;
  color: #E96036;
}
.ant-btn-text:focus > span,
.ant-btn-text:hover > span {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-decoration-thickness: initial;
  -webkit-text-decoration-style: initial;
          text-decoration-style: initial;
  -webkit-text-decoration-color: initial;
          text-decoration-color: initial;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus > span,
.ant-btn-text[disabled]:hover > span {
  color: #ABACBC;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}
.ant-btn-link {
  position: relative;
  border-color: transparent;
  background: transparent;
  color: #FF7826;
}
.ant-btn-link:focus,
.ant-btn-link:hover {
  border-color: transparent;
  background: transparent;
  color: #E96036;
}
.ant-btn-link > span {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-decoration-thickness: initial;
  -webkit-text-decoration-style: initial;
          text-decoration-style: initial;
  -webkit-text-decoration-color: initial;
          text-decoration-color: initial;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:hover {
  color: #ABACBC;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  gap: 16px;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  margin: 0;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0px;
}
.ant-tabs-tab:not(.ant-tabs-tab-disabled) {
  color: #D2D3DD;
  font-weight: 700;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #FFFCFB;
}
.ant-tabs-ink-bar {
  background: #FFFCFB;
}
.ant-tabs-tab {
  padding: 4px 0;
}
.ant-tabs-tab:not(.ant-tabs-tab-active, .ant-tabs-tab-disabled):hover {
  color: #E96036;
  font-weight: 700;
  border-bottom: 2px solid #E96036;
  padding-bottom: 2px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  color: #8687A0;
  background-color: #1E2024;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 16px 16px 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active + .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active + .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active + .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active + .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #2E2F3A;
  color: #FFFCFB;
  border: 1px 1px 0 1px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled + .ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled + .ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled + .ant-tabs-tab.ant-tabs-tab-disabled,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-disabled + .ant-tabs-tab.ant-tabs-tab-disabled {
  background-color: #1E202480;
  color: #8687A0;
}
.ant-tabs-tab:not(.ant-tabs-tab-active, .ant-tabs-tab-disabled):hover > .ant-tans-tab-btn > div > span {
  color: #E96036;
  font-weight: 700;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-disabled {
  color: #ABACBC;
}
.ant-slider-mark-text-active {
  color: #000;
}
.ant-slider-dot,
.ant-slider-dot-active {
  width: 0;
  height: 0;
  color: transparent;
  border-color: transparent;
  border-width: 0;
}
.alert .ant-tooltip-inner {
  background-color: #F5222D;
}
.alert .ant-tooltip-arrow-content {
  background-color: #F5222D;
}
.ant-popover {
  z-index: 99;
}
.ant-popover-no-style .ant-popover-arrow-content,
.ant-popover-no-style .ant-popover-arrow-content::before {
  background-color: unset;
  z-index: 99;
  width: 0;
  height: 0;
}
.ant-popover-no-style .ant-popover-inner {
  background-color: unset;
  z-index: 99;
}
.ant-popover-no-style .ant-popover-inner-content {
  padding: 0px;
  z-index: 99;
}
.ant-input-number-input {
  padding-left: 0px;
  padding-right: 0px;
}
.adm-page-indicator-dot {
  transition: all 100ms ease-in;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #E96036 !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  cursor: pointer;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: white !important;
  cursor: pointer !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  color: white !important;
  cursor: default !important;
  pointer-events: none !important;
}
.ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
}
.ant-steps-item-tail {
  color: #8687A0 !important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #8687A0 !important;
  cursor: pointer;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: rgba(55, 182, 112, 0.8) !important;
  background-color: rgba(55, 182, 112, 0.8) !important;
  cursor: pointer;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
  cursor: pointer;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #5B5C6C !important;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #8687A0 !important;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #8687A0 !important;
}
.ant-progress-circle .ant-progress-text {
  font-size: 1.5em !important;
  color: white !important;
}
.ant-select-selection-item {
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
}
.ant-select-arrow {
  color: white !important;
}
.ant-select-selector {
  height: 40px !important;
  width: 20rem !important;
  background-color: #1E2024 !important;
  border-width: 1px !important;
  border-color: #D2D3DD !important;
  color: #D2D3DD !important;
  display: flex !important;
  align-items: center !important;
}
.ant-select-open > .ant-select-selector {
  background-color: #D2D3DD !important;
  border-width: 1px !important;
  border-color: #1E2024 !important;
  color: #ACACBC !important;
}

