.rect {
  animation: fadeIn 5s ease infinite;
}

.rectAlt {
  animation: fadeIn 5s ease infinite;
  animation-delay: 2.5s;
}

@keyframes fadeIn {
  0%  { opacity: 0 }
  50%  { opacity: 1 }
  100%  { opacity: 0 }
}
