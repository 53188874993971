.marquee {
  height: 40px;
  width: 100%;
  overflow: hidden;

  position: relative;
  padding: 8px 0 4px 0;
}

.leftToRight {
  animation: 60s leftToRight infinite linear 
}

.rightToLeft {
  animation: 60s rightToLeft infinite linear
}

/* even out the elements */
.marqueeDiv {
  position: absolute;
  width: 100%;
  left: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
}

.leftToRight:hover, .rightToLeft:hover {
  animation-play-state: paused;
}

/* add delay at the end of animation so you dont start while another div is going */
@keyframes leftToRight {
  0% { transform: translate(0, 0) }
  91% { transform: translate(80rem, 0) }
  100% { transform: translate(80rem, 0) }
}

@keyframes rightToLeft {
  0% { transform: translate(0, 0) }
  91% { transform: translate(-80rem, 0) }
  100% { transform: translate(-80rem, 0) }
}